<template>
  <main class="home" role="main">
    <section class="home__section home__section--popup">
      <div v-if="!showConfirmation" class="popup">
        <div class="popup__enter">
          <div class="popup__content">
            <div class="popup__title">Вход</div>
            <div class="popup__text">Введите номер телефона, чтобы продолжить</div>
            <form class="popup__form requisition-form" @submit.prevent="loginRequest">
              <fieldset class="popup__fieldset" :class="{ 'is-error': v$.phone.$error || phoneError }">
                <legend class="popup__legend">Телефон</legend>
                <input class="popup__input" v-model="phone" type="tel" name="tel" placeholder="+7***"/>
                <div class="popup__error">{{ errorText }}</div>
              </fieldset>
              <div class="popup__note">
                <span class="popup__span">Нажав на&nbsp;кнопку, соглашаюсь на&nbsp;обработку </span>
                <a class="popup__link" href="https://api.autopodpiska.ru/document/personal-data-consent" target="_blank">персональных данных</a>
              </div>
              <div class="popup__buttons" :class="{ 'popup__buttons--disabled': loading.code }">
                <div class="popup__button">
                  <button class="button button" type="submit">Войти</button>
                </div>
                <div class="popup__button">
                  <router-link class="button button--transparent" to="/registration">Регистрация</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div v-else class="popup">
        <div class="popup__confirmation">
          <div class="popup__content">
            <div class="popup__title">Подтверждение
            </div>
            <div class="popup__text">Мы отправили вам код подтверждения на номер
              <div class="popup__num">{{ phone }}.
                <a href="#" @click="returnToLogin()">Изменить</a>
              </div>
            </div>
            <form class="popup__form requisition-form" @submit.prevent="codeRequest">
              <fieldset class="popup__fieldset" :class="{ 'is-error': smsError }">
                <legend class="popup__legend">Код из SMS</legend>
                <input class="popup__input" type="text" name="SMS" placeholder="****" v-model="sms"/>
                <div class="popup__error">Неверный код подтверждения</div>
              </fieldset>
              <div class="popup__note">
                <a v-if="countdown === 0" class="popup__send" href="#" @click.prevent="resendCode">Отправить новое сообщение</a>
                <span v-else class="popup__span">Вы сможете получить новое SMS через 00:{{ countdown }}</span>
              </div>
              <div class="popup__buttons" :class="{ 'popup__buttons--disabled': loading.confirm }">
                <div class="popup__button">
                  <button class="button button--requisition" type="submit">Вход</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import * as links from '../store/links';
import { useMeta } from 'vue-meta';
import routesInfo from '@/router/const';

export default {
  name: 'Login',
  setup () {
    useMeta({
      title: routesInfo.login.title,
      description: routesInfo.login.description,
      keywords: routesInfo.login.keywords
    });
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      showConfirmation: false,
      phone: '',
      countdown: 59,
      sms: '',
      phoneError: false,
      smsError: false,
      hash: '',
      loading: {
        code: false,
        confirm: false
      },
      errorText: 'Неверный формат телефона',
      errors: {
        'default': 'Неверный формат телефона',
        'user not registered': 'Пользователь не зарегистрирован. Пожалуйста пройдите регистрацию'
      }
    };
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      user: 'auth/user'
    }),
  },
  watch: {
    showConfirmation() {
      this.countDownTimer();
    }
  },
  validations () {
    return {
      phone: {required},
    };
  },
  created() {
    if (this.$route.query && this.$route.query.hash) {
      let hash = this.$route.query.hash;
      if (hash && this.sanitizeHash(hash)) {
        this.showConfirmation = true;
        this.hash = hash;
      }
    }
  },
  methods: {
    async loginRequest() {

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) return;

      this.phoneError = false;
      console.info('Phone::phoneRequest');
      this.loading.code = true;
      this.axios.post(
          `${links.API}/send-sms`,
          {
            phone: this.phone
          },
          {
            loading: {message: 'Trying to login...'}
          }
      )
          .then(async (response) => {
            this.hash = response.data.hash;

            if (!this.showConfirmation) {
              this.showConfirmation = true;
            }
            this.loading.code = false;
          })
          .catch((error) => {
            this.phoneError = true;
            this.loading.code = false;
            console.error(error);
            this.errorText = this.errors['default'];
            if (this.errors[error.response.data.message]) {
              this.errorText = this.errors[error.response.data.message];
            }
          });

      // this.showConfirmation = true;
    },
    countDownTimer() {
      if(this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    resendCode() {
      this.countdown = 59;
      this.countDownTimer();
      this.loginRequest();
    },
    async codeRequest() {
      this.loading.confirm = true;
      this.axios.post(
          `${links.API}/check-code`,
          {
            code: this.sms,
            hash: this.hash
          },
          {
            loading: {message: 'Trying to check sms...'}
          }
      )
          .then(async(response) => {
            this.smsError = false;
            this.$store.dispatch('auth/saveToken', response.data);

            await this.$store.dispatch('auth/fetchUser');
            if (this.user && this.user.car_id) {
              this.$router.push('/profile');
            } else {
              this.$router.push('/catalog');
            }
            this.loading.confirm = false;
          })
          .catch((error) => {
            console.error(error);
            this.smsError = true;
            this.loading.confirm = false;
          });
    },
    returnToLogin() {
      this.$router.push(this.$route.path);
      this.showConfirmation = false;
      this.hash = '';
    },
    sanitizeHash() {
      return true;
    },
  },
};
</script>
