import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from '../store';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = store.getters['auth/token'];
    if (token === null) {
      next({
        path: '/registration',
      });
    } else {
      let user = store.getters['auth/user'];
      if (user && !user.car_id && to.path === '/profile') {
        next({
          path: '/scoring',
        });
      } else {
        next();
      }
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
