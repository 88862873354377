<template>
  <template></template>
</template>

<script setup>
import { useMeta } from 'vue-meta';
import { defineProps } from 'vue';

const props = defineProps({
  car: {
    type: Object,
    required: true
  },
  price: {
    type: String,
    required: true
  }
});

useMeta({
  title: `${props.car.model} ${props.car.version} - по подписке`,
  description: `Автоподписка на ${props.car.model} ${props.car.version} от ${props.price} руб/мес. Издержки владения возьмем на себя.`,
  keywords: `${props.car.model}, ${props.car.model} по подписке, подписка на ${props.car.model}, ${props.car.model} автоподписка, ${props.car.model} ${props.car.version}, ${props.car.model} ${props.car.version} по подписке, подписка на ${props.car.model} ${props.car.version}, ${props.car.model} ${props.car.version} автоподписка, ${props.car.model} ${props.car.version} лизинг, ${props.car.model} ${props.car.version} аренда`
});
</script>
