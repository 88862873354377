import Catalog from '@/pages/Catalog';
import Car from '@/pages/Car';
import Login from '@/pages/Login';
import Landing from '@/pages/landing/Landing';
import Registration from '@/pages/Registration';
import Profile from '@/pages/Profile';
import Scoring from '@/pages/Scoring';
import Error404 from '@/pages/Error404';
import LandingUl from '@/pages/landingUl/Landing';
import CatalogUl from '@/pages/CatalogUl';
import CarUl from '@/pages/CarUl';

const routes = [
  {
    path: '/',
    component: Landing,
    name: 'landing',
  },
  {
    path: '/ul/',
    component: LandingUl,
    name: 'landingUl',
  },
  {
    path: '/ul/catalog',
    component: () => import('../layouts/MainLayoutUl.vue'),
    children: [
      {
        path: '/ul/catalog/:model',
        component: CatalogUl,
        name: 'catalogUlModel',
      },
      {
        path: '/ul/catalog',
        component: CatalogUl,
        name: 'catalogUl',
      },
      {
        path: '/ul/car//:id',
        component: CarUl,
        name: 'carUl',
      },
      {
        path: '/ul/car/:model/:id',
        component: CarUl,
        name: 'carUlModel',
      },
    ],
  },
  {
    path: '/catalog',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: '/catalog/:model',
        component: Catalog,
        name: 'catalogModel',
      },
      {
        path: '/catalog',
        component: Catalog,
        name: 'catalog',
      },
      {
        path: '/car/:id',
        component: Car,
        name: 'car',
      },
      {
        path: '/car/:model/:id',
        component: Car,
        name: 'carModel',
      },
      {
        path: '/login',
        component: Login,
        name: 'login',
      },
      {
        path: '/registration',
        component: Registration,
        props: (route) => ({ query: route.query.q }),
        name: 'registration',
      },
      {
        path: '/profile',
        component: Profile,
        name: 'profile',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/scoring/:step?',
        component: Scoring,
        name: 'scoring',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/booking',
        redirect: () => {
          return '/scoring/final';
        },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: Error404
  }
];

export default routes;