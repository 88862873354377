<template>
  <main class="home" role="main">
  <section class="home__section home__section--car" id="car">
      <div class="car">
        <div class="car__back">
          <btn-back></btn-back>
        </div>
        <div class="car__general">
          <div class="car__preview">
            <CarSlider :slides="car.photos"></CarSlider>
            <div v-if="car.total_trip > 1000" class="car__status">С пробегом</div>
          </div>
          <div class="car__information">
            <div class="car__content">
              <div class="car__headline">
                <h2 class="car__title">{{ car.make }} {{ car.version }}</h2>
                <a v-if="docs" class="car__download car__download--spec" :href="docs" target="_blank">Комплектация</a>
              </div>
              <div class="car__basic">
                <div class="car__engine">{{ getVolume(car.engine_volume) }}л, {{ car.engine_horse_power }} л.с., {{ car.fuel_type }}</div>
                <div class="car__drivetrain">{{ car.driving_gear_type }} привод</div>
                <div class="car__consumption">8,2л., смешанный</div>
              </div>
              <p class="car__text">
                <b>Наличие:&nbsp;</b>
                <span v-if="car.is_available">В наличии</span>
                <span v-else>В поставке</span>
              </p>
              <p v-if="car.is_available" class="car__text">
                <b>Розничная цена:&nbsp;</b>
                <span>{{ formatPriceResidual(car.expected_sale_price) }}</span>
              </p>
              <p class="car__text" v-if="car.lease_month_price != 0">
                <b>VIN:&nbsp;</b>
                <span>{{ car.vin }}</span>
              </p>
              <ul class="car__ul">
                <li>
                  <h3 class="car__list">Входит в стоимость подписки:</h3>
                </li>
                <li class="car__li">Техническое обслуживание</li>
                <li class="car__li">Полное КАСКО и ОСАГО</li>
                <li class="car__li">Хранение и замена шин</li>
                <li class="car__li">Постановка на учет и оплата налогов</li>
                <li class="car__li">Возможность выкупа после окончания подписки</li>
              </ul>
              <a class="car__download car__download--mob" :href="docs.spec" target="_blank">Комплектация</a>
            </div>
            <div v-if="!car.is_rented" class="car__order">
              <fieldset class="car__fieldset">
                <legend class="car__legend">Срок подписки</legend>
                <v-select
                    v-model="ratePlan"
                    label="select"
                    :options="ratePlansSelect"
                    :searchable="false"
                    @option:selecting="setSelected"
                >
                  <template #selected-option-container="{ option }">
                    <template v-if="option && Object.keys(option).length !== 0">
                      <span class="vs__selected">{{ option.select }}</span>
                    </template>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5391 0L5.83294 6L0.126817 0H5.83294H11.5391Z" fill="black"/>
                      </svg>
                    </span>
                  </template>
                  <template #no-options="{ }">
                    Сроки подписки уточняются
                  </template>
                </v-select>
              </fieldset>
              <div class="car-actions">
                <p v-if="['423', '424'].includes(id)" class="car__price-residual">AITO + трейд-ин! Меняйте свой старый автомобиль на новый AITO и наслаждайтесь комфортом с меньшими затратами. Узнайте, как получить двойную выгоду! </p>
                <div class="row">
                  <div v-if="!(user && user.car_id)" class="car__button">
                    <a v-if="car.is_available" class="button" href="#" @click.prevent="scoreCar">К оформлению</a>
                    <button v-else class="button" @click.prevent="offerCarRequest" :disabled="isOffered" :class="{'fs-14': isOffered}" v-text="textButton"></button>
                  </div>
                  <div class="car__button car__button--test">
                    <button  @click.prevent="carTestDriveRequest" :disabled="loadingTestDrive" class="button button--border" v-text="textButtonTestDrive"></button>
                  </div>
                  <div class="car__prices">
                    <p class="car__price">{{ formatPrice(ratePlansPrice) }}</p>
                    <p class="car__price-residual">{{ formatResidualPrice(carResidualPrice) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="car__detail">
          <div class="car__head">
            <div class="car__label car__label--specifications" @click="isSpec = true" :class="{'is-active': isSpec}">Характеристики</div>
            <div class="car__label car__label--equipment" @click="isSpec = false" :class="{'is-active': !isSpec}">Описание и комплектация</div>
          </div>
          <div class="car__table">
            <transition name="carspec">
              <div class="car__specifications" v-if="isSpec">
                <div class="car__item"><b>Тип двигателя:</b><span>{{ car.fuel_type }}</span></div>
                <div class="car__item"><b>Тип привода</b><span>{{ car.driving_gear_type }}</span></div>
                <div class="car__item"><b>Пробег</b><span>{{ car.total_trip }} км</span></div>
                <div class="car__item"><b>Цвет кузова</b><span>{{ car.color }}</span></div>
                <div class="car__item"><b>КПП:</b><span>{{ car.gear_box_type }}</span></div>
                <div class="car__item"><b>Объем л.</b><span>{{ getVolume(car.engine_volume) }}</span></div>
                <div class="car__item"><b>Тип кузова:</b><span>{{ car.body_type }}</span></div>
                <div class="car__item"><b>Мощность л.с.</b><span>{{ car.engine_horse_power }}</span></div>
                <div class="car__item"><b>Год выпуска</b><span>{{ car.manufacture_year }}</span></div>
                <div class="car__item"><b>Цвет салона</b><span>{{ car.color }}</span></div>
              </div>
              <div class="car__equipment" v-else>
                <div v-for="(option, index) in car.options.slice(0, 12)" class="car__item" :key="index">{{ option }}</div>
              </div>
            </transition>
          </div>
          <div class="car__document">
            <a class="car__download" href="https://api.autopodpiska.ru/document/contract_from_30april2024" target="_blank">Актуальное пользовательское соглашение</a>
          </div>
        </div>
      </div>
    </section>
    <offer-call/>
    <CarOrderPopup :show="isShow"/>
    <CallTestDriveRequest
      :show="showCallTestDriveRequest"
      :carId="car.id"
      :is-trade-in="car.model === 'AITO'"
      @close="carTestDriveClose"
      @closeRequest="carTestDriveCloseRequest"
    />
    <CarMeta v-if="car.id && ratePlansPrice" :car="car" :price="ratePlansPrice" />
  </main>
</template>

<script>
import OfferCall from '@/components/OfferCall';
import CarOrderPopup from '@/components/CarOrderPopup';
import BtnBack from '../components/BtnBack';
import CarSlider from '@/components/CarSlider';
import CallTestDriveRequest from '@/components/CallTestDriveRequest';
import CarMeta from '@/components/CarMeta';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import * as links from '../store/links';
import { mapGetters } from 'vuex';

export default {
  name: 'Car',
  components: {
    BtnBack,
    OfferCall,
    CarSlider,
    CarOrderPopup,
    vSelect,
    CallTestDriveRequest,
    CarMeta
  },
  data() {
    return {
      car: {},
      rclass: {},
      docs: {},
      carDefault: {
        photos: [
          require('@/assets/images/catalog/car-placeholder.png'),
        ],
      },
      isSpec: true,
      id: this.$route.params.id,
      isOffered: false,
      offeredCarIds: [],
      fullPath: this.$route.fullPath,
      textButton: 'Заказать',
      textButtonTestDrive: 'Тест-драйв',
      isShow: false,
      loading: false,
      ratePlan: null,
      ratePlans: [],
      ratePlansSelect: [],
      ratePlansPrice: null,
      carResidualPrice: null,
      showCallTestDriveRequest: false,
      loadingTestDrive: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    this.carRequest();
    this.ratePlansRequest();
    if (localStorage.offeredCarIds) {
      this.offeredCarIds = JSON.parse(localStorage.offeredCarIds);
      this.offeredCarIds.forEach((el) => {
        if (el == this.id) {
          this.isOffered = true;
          this.textButton = 'Заявка отправлена';
        }
      });
    }
  },
  methods: {
    offerCarRequest() {
      if (!this.user) {
        this.$router.push('/registration');

        if (this.$metrika) {
          this.$metrika.reachGoal('lk_zakaz');
        }

        return;
      }

      this.isOffered = true;
      this.textButton = 'Заявка отправлена';

      this.axios.post(
          `${links.API}/my/spec-booking`,
          {
            url: window.location.href,
            carId: this.car.id
          },
          {
            loading: {message: 'Trying to login...'}
          }
      )
          .then(async () => {
            this.offeredCarIds.push(this.car.id);
            localStorage.offeredCarIds = JSON.stringify(this.offeredCarIds);

            this.isShow = true;

            if (this.$metrika) {
              this.$metrika.reachGoal('lk_zakaz');
            }
          })
          .catch((error) => {
            console.error(error);
            this.isOffered = false;
            this.textButton = 'Заказать';
            this.$store.dispatch('auth/checkAuth', error);
          });
    },
    carTestDriveRequest() {
      this.loadingTestDrive = true;

      if (!this.user) {
        this.showCallTestDriveRequest = true;

        if (this.$metrika) {
          this.$metrika.reachGoal('card_testdrive_fs');
        }

        return;
      }

      this.axios.post(
          `${links.API}/back-call/test-drive`,
          {
            name: this.user?.name,
            phone: this.user?.phone,
            car_id: Number(this.car.id)
          },
          {
            loading: {message: 'Trying to login...'}
          }
      )
          .then(async () => {
            this.textButtonTestDrive = 'Отправлено';

            if (this.$metrika) {
              this.$metrika.reachGoal('card_testdrive_fs');
            }
          })
          .catch((error) => {
            console.error(error);
            this.loadingTestDrive = false;
            this.showCallTestDriveRequest = false;
            this.$store.dispatch('auth/checkAuth', error);
          });
    },
    carTestDriveClose() {
      this.showCallTestDriveRequest = false;
      this.textButtonTestDrive = 'Отправлено';
    },
    carTestDriveCloseRequest() {
      this.showCallTestDriveRequest = false;
      this.loadingTestDrive = false;
    },
    catalogRedirect() {
      this.$router.replace('/catalog');
    },
    carRequest() {
      this.axios.get(
      `${links.API}/cars/${this.id}`,
      {
        loading: {message: 'Trying to login...'}
      }
        )
        .then(async(response) => {
          if (!response.data) {
            this.catalogRedirect();
            return;
          }
          this.car = response.data;
          if (this.car.model === 'AITO') {
            this.textButtonTestDrive = 'Хочу Трейд-Ин';
          }
          this.docs = this.car.specification_url;
          this.rentClassRequest();
          this.carResidualPrice = this.car.car_residual_price;
        })
        .catch((error) => {
          console.error(error);
          this.catalogRedirect();
        });
    },
    ratePlansRequest() {
      this.axios.get(
      `${links.API}/rate-plans/${this.id}`,
      {
        loading: {message: 'Trying to login...'}
      }
        )
        .then(async(response) => {
          if (response.data && response.data !== null) {
            this.ratePlans = response.data;
            this.ratePlans.forEach((rp) => {
            this.ratePlansSelect.push(
                {
                  id: rp.id,
                  price: rp.price,
                  select: `${rp.months} месяцев (${rp.max_trip }км пробега)`,
                  car_residual_price: rp.car_residual_price,
                }
              );
            });
            this.ratePlan = this.ratePlansSelect[this.ratePlansSelect.length - 1];
            this.ratePlansPrice = this.ratePlan.price;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setSelected(value) {
      this.ratePlansPrice = value.price;
      this.carResidualPrice = value.car_residual_price;
    },
    rentClassRequest() {
      this.axios.get(
          `${links.API}/rent-classes`,
          {
            loading: {message: 'Trying to login...'}
          }
      )
        .then(async(response) => {
          this.rclass = response.data.find(rClassEl => rClassEl.id === this.car.rent_class_id);
          this.setDefault();
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch('auth/checkAuth', error);
        });
    },
    setDefault() {
      if (!this.car.photos.length) {
        this.car.photos = this.carDefault.photos;
        if (this.rclass && this.rclass.cover_photo.length) {
          this.car.photos =  new Array(this.rclass.cover_photo);
        }
      }
    },
    getVolume(num) {
      return Number((num*0.001).toFixed(2));
    },
    formatPrice(price) {
      return price > 0 ? `${price.toLocaleString('ru-RU')}руб. ежемесячно` : 'Цена уточняется';
    },
    formatResidualPrice(price) {
      return price > 0 ? `Стоимость выкупа: ${price.toLocaleString('ru-RU')}руб.` : 'Стоимость выкупа уточняется';
    },
    formatPriceResidual(price) {
      return price > 0 ? `${price.toLocaleString('ru-RU')}руб.` : 'Цена уточняется';
    },
    scoreCar() {
      this.$store.dispatch('cars/setChosenCar', this.id);
      if (this.$metrika) {
        this.$metrika.reachGoal('lk_ordering');
      }
      this.$router.push('/scoring');
      localStorage.ratePlan = JSON.stringify(this.ratePlan);
    }
  },
};
</script>
