<template>
  <Header @changePopupState="changePopupState"></Header>
  <main class="landing">
    <section class="landing__section--intro">
      <Intro @changePopupState="changePopupState"></Intro>
    </section>
    <section id="who" class="landing__section--who">
      <Who></Who>
    </section>
    <section id="equipment" class="landing__section--equipment">
      <Equipment></Equipment>
    </section>
    <section id="steps" class="landing__section--steps">
      <Steps></Steps>
    </section>
    <section id="catalog" class="landing__section--catalog">
      <Catalog></Catalog>
    </section>
    <aos-vue placement="top-bottom" :duration="800" animation="fade-up" :once="true">
      <section id="#about" class="landing__section--about">
        <About></About>
      </section>
    </aos-vue>
    <section id="faq" class="landing__section--faq">
      <Faq></Faq>
    </section>
    <aos-vue placement="top-bottom" :duration="800" animation="fade-up" :once="true">
      <section id="coverage" class="landing__section--coverage">
        <Coverage @changePopupState="changePopupState"></Coverage>
      </section>
    </aos-vue>
  </main>
  <Footer></Footer>
  <Popup @changePopupState="changePopupState" :isShow="showPopup" :type="popupType">
  </Popup>
</template>

<script setup>
import Header from './components/Header';
import Intro from './components/Intro';
import Who from './components/Who';
import Equipment from './components/Equipment';
import Steps from './components/Steps';
import Catalog from './components/Catalog';
import About from './components/About';
import Faq from './components/Faq';
import Coverage from './components/Coverage';
import Footer from '@/layouts/Footer';
import Popup from './components/Popup';
import { ref } from 'vue';
import { useMeta } from 'vue-meta';
import routesInfo from '@/router/const';

const popupType = ref('');
const showPopup = ref(false);

useMeta({
  title: routesInfo.landing.title,
  description: routesInfo.landing.description,
  keywords: routesInfo.landing.keywords
});

const changePopupState = (currentType) => {
  if (currentType) {
    popupType.value = currentType;
  }
  showPopup.value = !showPopup.value;
};
</script>