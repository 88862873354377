<template>
  <div class="card is-stock" :class="{ 'is-reserve': card.is_available, 'card--disabled': card.is_rented }">
    <card-slider :slides="photos"></card-slider>
      <router-link @click="metrika" class="card__content" :to="'/car/' + model + '/' + card.id">
      <div class="card__title">{{ card.make }} {{ card.version }}</div>
      <div class="card_content-wr">
        <div class="card__engine">{{ getVolume(card.engine_volume) }}л, {{ card.engine_horse_power }} л.с.</div>
        <div class="card__drivetrain">{{ card.driving_gear_type }} привод</div>
        <div class="card__consumption">{{ card.fuel_type }}</div>
        <div class="card__price">{{ formatPrice(card.lease_month_price) }}</div>
        <div class="card__statuses">
          <div v-if="card.is_available" class="card__status">В наличии</div>
          <div v-else class="card__status">В поставке</div>
          <div v-if="card.total_trip > 1000" class="card__status red">С пробегом</div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import CardSlider from './CardSlider';

export default {
  name: 'Card',
  components: {
    CardSlider
  },
  props: {
    card: {
      type: Object,
    },
    rclass: {
      type: Object,
    }
  },
  computed: {
    photos() {
      if (!this.card.photos?.length && this.rclass?.photos?.length) {
        return this.rclass.photos;
      }

      return this.card.photos;
    },
    fullName() {
      return `${this.card.make} ${this.card.version}`;
    },
    minPrice() {
      return this.card.lease_month_price;
    },
    model() {
      return this.rclass?.brief.replace(/\s+/g, '-');
    },
  },
  methods: {
    metrika() {
      if (this.$metrika) {
        this.$metrika.reachGoal('open_card_auto_catalog');
      }
    },
    getVolume(num) {
      return Number((num * 0.001).toFixed(2));
    },
    formatPrice(price) {
      return price > 0 ? `${price.toLocaleString('ru-RU')}руб. ежемесячно` : 'Цена уточняется';
    },
  }
};
</script>
